<template>
  <div id="layout-header">
    <global-header></global-header>
  </div>
</template>

<script>
import GlobalHeader from '@/components/global-header'

export default {
  name: 'LayoutHeader',
  components: {
    GlobalHeader
  }
}
</script>

<style scoped lang="scss"></style>
