import { Message } from 'element-ui'

const GENERIC_ERROR = -100
const HTTP_ERROR = -200
const IO_ERROR = -300
const SECURITY_ERROR = -400
const INIT_ERROR = -500
const FILE_SIZE_ERROR = -600
const FILE_EXTENSION_ERROR = -601
const FILE_DUPLICATE_ERROR = -602
const IMAGE_FORMAT_ERROR = -700
const IMAGE_DIMENSIONS_ERROR = -702

const errors = {
  [GENERIC_ERROR]: '组件内部发生了通用错误',
  [HTTP_ERROR]: '网络发生了错误',
  [IO_ERROR]: '安全问题产生的错误',
  [SECURITY_ERROR]: '网络发生了错误',
  [INIT_ERROR]: '组件初始化时发生错误',
  [FILE_SIZE_ERROR]: '文件太大超过限制',
  [FILE_EXTENSION_ERROR]: '选择的文件类型不符合后缀要求',
  [FILE_DUPLICATE_ERROR]: '配置不允许重复文件',
  [IMAGE_FORMAT_ERROR]: '图片格式错误',
  [IMAGE_DIMENSIONS_ERROR]: '文件大小超过了plupload所能处理的最大值'
}

class Uploader {
  constructor({
    /**
     * plupload的属性[参考](http://chaping.github.io/plupload/doc)
     */
    browse_button,
    url = '/',
    filters = {},
    multi_selection = false,

    /**
     * plupload的监听事件[参考](http://chaping.github.io/plupload/doc)
     */
    PostInit,
    OptionChanged,
    Refresh,
    StateChanged,
    UploadFile,
    BeforeUpload,
    QueueChanged,
    UploadProgress,
    FilesRemoved,
    FileFiltered,
    FilesAdded,
    FileUploaded,
    ChunkUploaded,
    UploadComplete,
    Error,
    Destroy,

    /**
     * 自定义参数
     */
    random = true,
    every = false, // 是否没次需要生成token
    suffix, // oss保存地址的目录类型
    auto_start = true, // 是否自动上传, 默认为true
    extensions,
    max_file_size
  } = {}) {
    // uploader 上传对象
    this.uploader = null
    // 是否自动上传, 默认为true
    this.auto_start = auto_start
    // this.extensions = extensions
    // this.max_file_size = max_file_size
    // 上传进度条元素容器
    this.processEl = null
    this.processTxtEl = null

    // 组合 extensions 和 max_file_size 到 filters
    filters.max_file_size =
      max_file_size !== undefined ? max_file_size : filters.max_file_size
    // filters.mime_types = [ extensions ? ...([]) :...([]), ...(filters.mime_types || [])]
    filters.mime_types = [
      ...(filters.mime_types || []),
      ...(extensions ? [{ title: extensions, extensions }] : [])
    ]
    this.random = random
    this.every = every
    this.suffix = suffix
    // uploader 上传配置
    this.uploaderOption = {
      browse_button,
      url,
      filters,
      multi_selection,

      PostInit,
      OptionChanged,
      Refresh,
      StateChanged,
      UploadFile,
      BeforeUpload,
      QueueChanged,
      UploadProgress,
      FilesRemoved,
      FileFiltered,
      FilesAdded,
      FileUploaded,
      ChunkUploaded,
      UploadComplete,
      Error,
      Destroy
    }
  }

  initUploader() {
    const _this = this
    const uploaderOption = this.uploaderOption
    this.uploader = new window.plupload.Uploader({
      runtimes: 'html5,flash,silverlight,html4',
      browse_button: uploaderOption.browse_button,
      multi_selection: uploaderOption.multi_selection,
      flash_swf_url: '../plupload-2.1.2/js/Moxie.swf',
      silverlight_xap_url: '../plupload-2.1.2/js/Moxie.xap',
      url: uploaderOption.url,
      filters: uploaderOption.filters,
      init: {
        PostInit(uploader) {
          if (uploaderOption.PostInit) {
            uploaderOption.PostInit.call(null, uploader)
          }
        },
        OptionChanged(uploader, option_name, new_value, old_value) {
          if (uploaderOption.OptionChanged) {
            uploaderOption.PostInit.call(
              null,
              uploader,
              option_name,
              new_value,
              old_value
            )
          }
        },
        Refresh(uploader) {
          if (uploaderOption.Refresh) {
            uploaderOption.Refresh.call(null, uploader)
          }
        },
        StateChanged(uploader) {
          if (uploaderOption.StateChanged) {
            uploaderOption.StateChanged.call(null, uploader)
          }
        },
        UploadFile(uploader) {
          if (uploaderOption.UploadFile) {
            uploaderOption.UploadFile.call(null, uploader)
          }
        },
        BeforeUpload(uploader, file) {
          if (uploaderOption.BeforeUpload) {
            uploaderOption.BeforeUpload.call(null, uploader)
            return
          }
          // 在上传之前的最后一个钩子函数设置上传选项参数
          _this.setUploaderOption(file)
        },
        QueueChanged(uploader) {
          if (uploaderOption.QueueChanged) {
            uploaderOption.QueueChanged.call(null, uploader)
            return
          }
        },
        UploadProgress(uploader, file) {
          if (uploaderOption.UploadProgress) {
            uploaderOption.UploadProgress.call(null, uploader, file)
            return
          }
          _this.processTxtEl.innerText = file.percent + '%'
        },
        FilesRemoved(uploader, files) {
          if (uploaderOption.FilesRemoved) {
            uploaderOption.FilesRemoved.call(null, uploader, files)
          }
        },
        FileFiltered(uploader, file) {
          if (uploaderOption.FileFiltered) {
            uploaderOption.FileFiltered.call(null, uploader, file)
          }
        },
        FilesAdded(uploader, files) {
          if (uploaderOption.FilesAdded) {
            uploaderOption.FilesAdded.call(null, uploader, files)
            return
          }
          if (_this.auto_start) {
            // 自动上传的时候, 就直接调用start方法
            _this.start()
          }
        },
        FileUploaded(uploader, file, responseObject) {
          if (uploaderOption.FileUploaded) {
            uploaderOption.FileUploaded.call(
              null,
              uploader,
              file,
              responseObject
            )
          }
        },
        ChunkUploaded(uploader, file, responseObject) {
          if (uploaderOption.ChunkUploaded) {
            uploaderOption.ChunkUploaded.call(
              null,
              uploader,
              file,
              responseObject
            )
          }
        },
        UploadComplete(uploader, files) {
          _this.processEl.remove()
          _this.processEl = null
          const multi_selection = uploaderOption.multi_selection
          if (typeof uploaderOption.UploadComplete === 'function') {
            const urls = files.map((item) => item.url)
            uploaderOption.UploadComplete.call(
              null,
              uploader,
              multi_selection ? files : files[files.length - 1],
              multi_selection ? urls : urls[urls.length - 1],
              multi_selection ? undefined : files,
              multi_selection ? undefined : urls
            )
          }
        },
        Error(uploader, errObject) {
          if (uploaderOption.Error) {
            uploaderOption.Error.call(
              null,
              uploader,
              errObject,
              errObject && errObject.message
            )
            return
          }
          if (errObject && errObject.code) {
            Message.error(errors[errObject.code])
          } else {
            Message.error('发生了错误')
          }
          if (_this.processTxtEl) _this.processTxtEl.innerText = '重新上传'
        },
        Destroy(uploader) {
          if (uploaderOption.Destroy) {
            uploaderOption.Destroy.call(null, uploader)
            return
          }
        }
      }
    })
    this.uploader.init()
  }

  setUploaderOption(file) {
    throw new Error('You must implement setUploaderOption')
  }

  start() {
    if (!this.processEl) {
      this.initProcessEl()
    }
    this.processTxtEl.innerText = '上传中...'
    this.uploader.start()
  }

  initProcessEl() {
    const parent = this.uploaderOption.browse_button
    const processEl = document.createElement('span')
    processEl.style.cssText =
      'position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);'
    const processTxtEl = document.createElement('span')
    processTxtEl.style.cssText =
      'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff;font-size: 12px;'
    processEl.appendChild(processTxtEl)
    this.processEl = processEl
    this.processTxtEl = processTxtEl
    parent.style.overflow = 'hidden'
    parent.appendChild(processEl)
  }
}

export default Uploader
