<template>
  <div class="components__left-content">
    <breadcrumb></breadcrumb>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb'

export default {
  name: 'LeftContent',
  components: {
    Breadcrumb
  }
}
</script>
