import { paginationKeys } from '../../../table/src/config/pagination'
import { tablePropsKeys } from '../../../table/src/config/table-props'
import { propsKeys as proTablePropsKeys } from '../../../table/src/config/props'
import { listPageTablePropsKeys } from './table-props'

const AllTablePropsKeys = [
  ...paginationKeys,
  ...tablePropsKeys,
  ...proTablePropsKeys,
  ...listPageTablePropsKeys
]

export default AllTablePropsKeys
