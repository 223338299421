import YForm from './form'
import YTable from './table'
import YListPage from './list-page'

const files = require.context('./', true, /^\.\/.+\/index.js$/)

const components = files.keys().reduce((pre, next) => {
  const file = files(next).default
  if (file?.name) {
    return [...pre, [file.name, file]]
  }
  return [...pre]
}, [])

export const install = function (Vue) {
  components.forEach(([name, component]) => {
    Vue.component(name, component)
  })
}

export default {
  install
}

export { YForm, YTable, YListPage }
