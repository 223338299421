<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="item in breadcrumbList" :key="item.realPath">
        {{ item.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Breadcrumb',
  computed: {
    ...mapState({
      breadcrumbList: (state) => {
        const matched = state.currentRoute.matched.map((item) => item.meta)
        return matched.slice(1) || []
      }
    })
  }
}
</script>
