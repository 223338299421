const list2map = (list = [], value = 'value', label = 'label') => {
  return list.reduce((pre, next) => {
    return {
      ...pre,
      [next[value]]: next[label],
      [next[label]]: next[value],
      [`_${next[value]}`]: next
    }
  }, {})
}

export default list2map
