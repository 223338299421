<template>
  <div class="layout__basis">
    <el-container>
      <el-aside class="layout__basis-sider yx__line-right" width="140px">
        <sider-view :data="routes" :currentRoute="currentRoute"></sider-view>
      </el-aside>
      <el-main :style="{ padding: 0 }">
        <el-container
          :style="{ height: '100vh', overflowY: 'auto', display: 'block' }"
        >
          <el-header class="yx__line-bottom">
            <header-view></header-view>
          </el-header>
          <el-main :style="{ minHeight: 'calc(100vh - 168px)' }">
            <router-view></router-view>
          </el-main>
          <!-- <el-footer>
            <footer-view></footer-view>
          </el-footer> -->
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import HeaderView from '../header'
import SiderView from '../sider'
import FooterView from '../footer'

export default {
  name: 'LayoutBasis',
  components: {
    HeaderView,
    SiderView,
    FooterView
  },
  computed: {
    ...mapState(['routes', 'currentRoute'])
  }
}
</script>

<style lang="scss" scoped>
.layout__basis {
  .yx__line-right {
    &::after {
      // width: 2px;
      background-color: #a9afb5;
    }
  }
}
</style>
