<template>
  <div class="layout-footer">
    <global-footer></global-footer>
  </div>
</template>

<script>
import GlobalFooter from '@/components/global-footer'

export default {
  name: 'LayoutFooter',
  components: {
    GlobalFooter
  }
}
</script>

<style scoped lang="scss">
.footer-layout {
  height: 100%;
}
</style>
