import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './libs' // 使用组件库
import './fns' // 全局方法
import './directives' // 自定义全局指令
import * as filters from './filters' // 自定义全局过滤
Object.entries(filters).forEach(([k, v]) => {
  Vue.filter(k, v)
})
import './global.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
