<template>
  <div class="border-form-item">
    <el-form-item v-bind="formItemProps">
      <slot></slot>
    </el-form-item>
  </div>
</template>

<script>
import { FormItem } from 'element-ui'

export default {
  components: {
    [FormItem.name]: FormItem
  },
  props: {
    formItemProps: Object
  }
}
</script>

<style scoped lang="scss">
.border-form-item {
  padding-top: 10px;
  padding-right: 10px;
  border: 1px solid #eaeefb;
}
</style>
