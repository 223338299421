/**
 * 枚举列表
 *
 * testList: [{ label: 'testName1', value: '1' }, { label: 'testName2', value: '2' }]
 *
 * 枚举对象
 * testMap: { 1: 'testName1', '2': testName2, testName1: 1, testName2: 2, _1: { label: 'testName1', value: '1' }, _2: { label: 'testName2', value: '2' } }
 */
import list2map from '@/utils/list2map'

const files = require.context('./', false, /.*\/(?!index).*\.js$/)

const enums = files.keys().reduce((pre, next) => {
  const moduleName = next.replace(/.\/_(.*).js/, '$1')
  return {
    ...pre,
    [`${moduleName}List`]: files(next).default
  }
}, {})

export const enumsMap = Object.entries(enums).reduce((pre, [key, item]) => {
  return {
    ...pre,
    [key.replace(/List$/, 'Map')]: list2map(item)
  }
}, {})

export default enums
