import pagination from '../../../table/src/config/pagination'
import tableProps from '../../../table/src/config/table-props'
import proTableProps from '../../../table/src/config/props'
import { firstLetterToUpper } from '../utils/tools'
import { TABLE } from './prefix'

const listPageTableProps = Object.entries({
  ...pagination,
  ...tableProps,
  ...proTableProps
}).reduce(
  (pre, [k, v]) => ({
    ...pre,
    [`${TABLE}${firstLetterToUpper(k)}`]: v
  }),
  {}
)

export const listPageTablePropsKeys = Object.keys(listPageTableProps)

export default listPageTableProps
