import serve from '@/serve'
import { Message } from 'element-ui'

function loadUrl({ filename, url }) {
  const eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  eleLink.href = url
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

const download = ({ fn, url = '', params = {}, filename = '下载文档' }) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (url) {
      loadUrl({ url, filename })
      resolve()
      return
    }
    if (!serve[fn]) {
      reject('传入参数错误')
      return
    }

    const res = await serve[fn]({
      params
    })

    if (res === serve.FAIL) {
      return
    }

    if (res.size === 0) {
      Message.warning('暂无下载数据')
      return
    }

    const blob = new Blob([res])
    const blobUrl = URL.createObjectURL(blob)
    loadUrl({ url: blobUrl, filename })
  })
}

export default download
