<template>
  <el-form-item class="form-item" v-bind="formItemProps">
    <slot></slot>
  </el-form-item>
</template>

<script>
import { FormItem } from 'element-ui'

export default {
  components: {
    [FormItem.name]: FormItem
  },
  props: {
    formItemProps: Object
  }
}
</script>
