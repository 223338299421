import { listPageTablePropsKeys } from '../config/table-props'
import { propsKeys as proTablePropsKeys } from '../../../table/src/config/props'
import { paginationKeys } from '../../../table/src/config/pagination'
import { tablePropsKeys } from '../../../table/src/config/table-props'

import AllTablePropsKeys from '../config/AllTablePropsKeys'

import { TABLE } from '../config/prefix'

import { firstLetterToUpper } from './tools'

const allProTablePropsKeys = [
  ...paginationKeys,
  ...tablePropsKeys,
  ...proTablePropsKeys
]

const formatTableProps = (props) => {
  const newProps = {}

  // 只要对AllTablePropsKeys中包含的属性
  const validTableProps = Object.entries(props).filter(
    ([k, v]) =>
      AllTablePropsKeys.includes(k) &&
      /**
       * 在申请属性的时候, 默认把listPageTablePropsKeys属性都设置了undefined
       * 可以避免listPageTablePropsKeys值为undefined的属性把proTablePropsKeys的值给覆盖了的情况
       */
      v !== undefined
  )

  validTableProps.forEach(([k, v]) => {
    if (listPageTablePropsKeys.includes(k)) {
      const proTableProp = allProTablePropsKeys.find((key) => {
        return k === `${TABLE}${firstLetterToUpper(key)}`
      })
      if (proTableProp) {
        newProps[proTableProp] = v
        return
      }
    }

    if (allProTablePropsKeys.includes(k)) {
      if (newProps[k] === undefined) {
        // 同样的属性声明两次, 比如y-f-inline和inline都传入了, 那么舍弃inline的传入
        newProps[k] = v
      }
      return
    }
  })
  return newProps
}

export default formatTableProps
