import memoizeOne from 'memoize-one'
import isEqual from 'lodash/isEqual'

/**
 * 增加路由配置数据的方法
 * 增加的配置有：
 * realPath 完成的路由字符串
 * parent 父路由
 */
export const formatRoutesByEnhanceMeta = (routes) => {
  const fn = (routes, parent) => {
    return routes.reduce((pre, next) => {
      if (!next.meta) {
        next.meta = {}
      }
      if (parent) {
        const parentRealPath = parent.meta.realPath
        next.meta.realPath = `${
          parentRealPath === '/' ? parentRealPath : parentRealPath + '/'
        }${next.path}`
        next.meta.parent = parent
      } else {
        next.meta.realPath = next.path.startsWith('/')
          ? next.path
          : '/' + next.path
        next.meta.parent = null
      }
      if (next.children?.length) {
        next.children = fn(next.children, next)
      }
      return [...pre, next]
    }, [])
  }

  return fn(routes)
}

export default memoizeOne(formatRoutesByEnhanceMeta, isEqual)
