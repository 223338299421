<template>
  <div class="global-content">
    <div class="left-content-wrap">
      <left-content></left-content>
    </div>
    <div class="right-content-wrap">
      <right-content></right-content>
    </div>
  </div>
</template>

<script>
import LeftContent from './components/left-content'
import RightContent from './components/right-content'

export default {
  name: 'GlobalHeader',
  components: {
    LeftContent,
    RightContent
  }
}
</script>

<style scoped lang="scss">
.global-content {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
