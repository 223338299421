<script>
import {
  FormItem,
  Row,
  Col,
  Switch,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  DatePicker,
  TimePicker,
  Input,
  InputNumber,
  Rate,
  ColorPicker,
  Transfer,
  Slider,
  Cascader,
  RadioGroup,
  Radio
} from 'element-ui'

import renderItem from '../utils/renderItem'
import formItemConfig from '../config/form-item-config'

export default {
  components: {
    [FormItem.name]: FormItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Switch.name]: Switch,
    [Checkbox.name]: Checkbox,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Rate.name]: Rate,
    [ColorPicker.name]: ColorPicker,
    [Transfer.name]: Transfer,
    [Slider.name]: Slider,
    [Cascader.name]: Cascader,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    model: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [renderItem],
  render(h) {
    try {
      const {
        type = 'input',
        formItemProps: { prop },
        render
      } = this.field

      if (render) {
        const content = render.call(this, h, prop, this.field, this.model)

        if (content === null || content === undefined) {
          return ''
        }

        // 返回的元素自带标签 那么直接返回
        if (content.constructor.name === 'VNode') {
          if (content.tag === 'template') {
            // 处理元素为空标签的情况下, 默认修改为span
            content.tag = 'div'
          }
          return content
        }

        // 返回的元素没有标签, 外面包裹一层标签
        return <span>{content}</span>
      }

      const currentRender =
        formItemConfig[`key-${type}`] || formItemConfig['key-input']

      const renderItem = this[currentRender.fn]

      // 默认配置赋值
      Object.assign(this.field.attributes, {
        ...currentRender.defaultAttr,
        ...this.field.attributes
      })

      return (
        <div class="dynamic-form">
          {renderItem?.(h, this.field, this.model)}
        </div>
      )
    } catch (e) {
      // console.warn(e)
      return (
        <span>
          请联系开发管理人员 {e?.message ? `error: ${e.message}` : ''}
        </span>
      )
    }
  }
}
</script>

<style scoped lang="scss"></style>
