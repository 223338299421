<template>
  <div class="layout__user">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'LayoutUser',
  components: {}
}
</script>
